import React from 'react'
import PropTypes from 'prop-types'

import InlineImage from '@/component/Common/InlineImage'
import InlineImageCarousel from '@/component/Common/InlineImageCarousel'
import Floater from '../../Primitive/Floater'

const InlineImageWidget = ({ inlineImageImages }) => {
  if (!inlineImageImages || !inlineImageImages.length) return null
  if (inlineImageImages.length === 1) {
    const firstImageCrop =
      inlineImageImages && inlineImageImages[0] && inlineImageImages[0].crop
    const Wrapper = firstImageCrop === '16:9' ? React.Fragment : Floater
    return (
      <Wrapper size="small" align="left">
        <InlineImage images={inlineImageImages} />
      </Wrapper>
    )
  }
  if (inlineImageImages.length > 1) {
    return (
      <>
        <InlineImageCarousel images={inlineImageImages} />
      </>
    )
  }
}

InlineImageWidget.propTypes = {
  inlineImageImages: PropTypes.array
}

export default InlineImageWidget
