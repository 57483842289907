import PropTypes from 'prop-types'
import React from 'react'
import YouTubeEmbed from '@/component/Primitive/YouTubeEmbed'
import VimeoEmbed from '@/component/Primitive/VimeoEmbed'
import Trailer from '@/component/Common/Trailer'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'

const VideoWidget = (props) => {
  const { videoId, videoProvider } = props
  return (
    <>
      {videoProvider === 'MovieXchange' && (
        <Trailer
          hlsStreamUrl={`https://film-cdn.moviexchange.com/api/cdn/release/${videoId}/media/TrailerVideo?width=0&height=0`}
        />
      )}
      {['YouTube', 'Vimeo'].includes(videoProvider) && (
        <ResponsiveMedia ratio={9 / 16}>
          {videoProvider === 'YouTube' && <YouTubeEmbed videoId={videoId} />}
          {videoProvider === 'Vimeo' && <VimeoEmbed videoId={videoId} />}
        </ResponsiveMedia>
      )}
    </>
  )
}

VideoWidget.propTypes = {
  videoId: PropTypes.string,
  videoProvider: PropTypes.string
}

export default VideoWidget
