import PropTypes from 'prop-types'
import React from 'react'
import SoundcloudEmbed from '@/component/Primitive/SoundcloudEmbed'
const AudioWidget = (props) => {
  const { url, title } = props
  return (
    <>
      <SoundcloudEmbed url={url} hideRelated={false} title={title} />
    </>
  )
}

AudioWidget.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string
}

export default AudioWidget
