import React from 'react'
import PropTypes from 'prop-types'

import Type from '@/component/Primitive/Type'
import SmartLink from '@/component/Primitive/SmartLink'
import AgeRating from '@/component/Primitive/AgeRating'

import styles from './FilmRatingStrip.module.scss'

const FilmRatingStrip = ({ ratings }) => (
  <div className={styles.FilmRatingStrip}>
    <Type as="div" size="title" className={styles.Title}>
      Film Ratings
    </Type>
    <div className={styles.RatingsWrapper}>
      {ratings.links.map((link) => (
        <SmartLink
          key={link.rating}
          className={styles.Link}
          to={link.to}
          as={link.as}
        >
          <AgeRating
            className={styles.AgeRating}
            rating={link.rating}
            key={`Rating-${link.rating}`}
          />
        </SmartLink>
      ))}
    </div>
    <Type
      as="div"
      size="subtitle"
      color="teal"
      className={styles.LearnRatingsText}
    >
      <SmartLink to="/section" as="/film-ratings">
        Learn all about film ratings{' '}
      </SmartLink>
    </Type>
  </div>
)

FilmRatingStrip.propTypes = {
  ratings: PropTypes.object
}

export default FilmRatingStrip
