import React from 'react'
import imagePropTypes from '@/shape/image'
import classNames from 'classnames'
import { string, shape } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
// import SmartLink from '@/component/Primitive/SmartLink'
import Expander from '@/component/Primitive/Expander'

import styles from './Bio.module.scss'

const Bio = ({ name, title, content, img }) => {
  const isDark = useDarkContext()
  return (
    <div className={classNames(styles.Bio, isDark && styles.isDark)}>
      <ResponsiveMedia ratio={1}>
        <ResponsiveImage {...img} />
      </ResponsiveMedia>
      <div className={styles.BioWrapper}>
        <Type as="h3" size="base" bold>
          {name}
        </Type>
        <Type as="p" size="base">
          {title}
        </Type>
      </div>
      {content && (
        <Expander title="Read bio" classNames={{ title: styles.Title }}>
          <div className={styles.ExpanderCopy}>{content}</div>
        </Expander>
      )}
    </div>
  )
}

export default Bio

Bio.propTypes = {
  name: string.isRequired,
  title: string,
  content: string.isRequired,
  img: shape(imagePropTypes).isRequired,
  url: string
}
