import React from 'react'
import { arrayOf, shape } from 'prop-types'
import imagePropTypes from '@/shape/image'

import CaptionedMedia from '@/component/Primitive/CaptionedMedia'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'

import inlineImageMapper from '../InlineImageCarousel/lib/mapper'
import styles from './InlineImage.module.scss'

const singleImageMapper = {
  '16:9': 'inline_16x9_1920',
  '1:1': 'inline_1x1_326',
  Free: 'inline_free'
}

const cropToRatio = (crop) => {
  const ratios = crop && crop.split(':')
  const height = ratios && ratios[0]
  const width = ratios && ratios[1]
  return width / height
}

const selectImage = (crop, ratio, image) =>
  crop === 'Free' ? (
    <img src={image.src} alt={image.alt} className={styles.InlineImage} />
  ) : (
    <ResponsiveMedia ratio={ratio}>
      <ResponsiveImage {...image} />
    </ResponsiveMedia>
  )

const InlineImage = ({ images }) => {
  const mappedImages = inlineImageMapper(singleImageMapper, images)
  const firstImage = mappedImages && mappedImages[0]
  const crop = firstImage && firstImage.crop
  const ratio = cropToRatio(crop)
  return (
    <CaptionedMedia
      caption={firstImage && firstImage.caption ? firstImage.caption : null}
    >
      {firstImage && firstImage.link ? (
        <a href={firstImage.link} target="_blank" rel="noreferrer">
          {selectImage(crop, ratio, firstImage)}
        </a>
      ) : (
        selectImage(crop, ratio, firstImage)
      )}
    </CaptionedMedia>
  )
}

InlineImage.propTypes = {
  images: arrayOf(shape(imagePropTypes))
}

export default InlineImage
