import React from 'react'
import { array, string } from 'prop-types'

import ContentSummaryGrid from '@/component/Common/ContentSummaryGrid'
import formatArticles from './lib/article-formatter'

const ArticleGrid = (props) => {
  const { articles, title, subtitle } = props
  if (!articles || !articles.length) return null
  const formattedArticles = formatArticles(articles, title)

  return (
    <ContentSummaryGrid
      items={formattedArticles}
      title={title}
      subtitle={subtitle}
    />
  )
}

ArticleGrid.propTypes = {
  articles: array,
  title: string,
  subtitle: string
}

export default ArticleGrid
