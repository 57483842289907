import PropTypes from 'prop-types'
import React from 'react'
import { useDarkContext } from '@/component/Context/DarkContext'

import Prose from '@/component/Primitive/Prose'

const Standfirst = ({ article }) => {
  const isDark = useDarkContext()
  const standfirst = article && article.standfirst
  if (!standfirst) return null
  return (
    <>
      <Prose inverse={isDark}>
        <h2>{standfirst}</h2>
      </Prose>
    </>
  )
}

Standfirst.propTypes = {
  article: PropTypes.shape({
    standfirst: PropTypes.string
  })
}

export default Standfirst
