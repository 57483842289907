import React from 'react'
import LogoCarousel from '@/component/Common/LogoCarousel'
import { string, array } from 'prop-types'

const LogoCarouselWidget = ({ title, images }) => {
  if (!images || !images.length) return null
  return <LogoCarousel title={title} images={images} />
}

LogoCarouselWidget.propTypes = {
  title: string,
  images: array
}

export default LogoCarouselWidget
