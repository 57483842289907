import ContentSummaryGrid from '@/component/Common/ContentSummaryGrid'
import React from 'react'
import propTypes from 'prop-types'

const RelatedArticles = (props) => {
  const { articles, title, subtitle } = props
  if (!articles || !articles.length) return null
  const formattedArticles =
    articles &&
    articles.map((article) => {
      const img =
        article &&
        article.images &&
        article.images.thumbnail_3x2_438 &&
        article.images.thumbnail_3x2_438[0]
      const url = img && img.url
      const alt = img && img.alt
      return {
        img: { src: url, alt: alt || '' },
        heading: article && article.headline,
        body: article && article.sell,
        link: article && article.fullUrlPath,
        contentRating: article && article.contentRating
      }
    })
  return (
    <ContentSummaryGrid
      items={formattedArticles}
      title={title}
      subtitle={subtitle}
    />
  )
}

RelatedArticles.propTypes = {
  articles: propTypes.array,
  title: propTypes.string,
  subtitle: propTypes.string
}

export default RelatedArticles
