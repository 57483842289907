import React from 'react'
import { shape, arrayOf, string, object, bool } from 'prop-types'

import SubGroup from './component/SubGroup'
import Type from '@/component/Primitive/Type'

import styles from './Timeline.module.scss'

const Timeline = ({ timeline }) => (
  <>
    {timeline.map((item, i) => {
      const { title, subGroups } = item
      return (
        <div
          className={styles.TimelineItemWrapper}
          key={`Timeline-${title}-${i}`}
        >
          <Type size="display3" className={styles.TimelineTitle} color="teal">
            {title}
          </Type>
          <div className={styles.TimelineItem}>
            <div className={styles.VerticalLine} />
            {subGroups.map((group, i) => {
              const { title, milestones } = group
              return (
                <SubGroup
                  title={title}
                  milestones={milestones}
                  key={`SubGroup-${title}-${i}`}
                />
              )
            })}
          </div>
        </div>
      )
    })}
  </>
)

Timeline.propTypes = {
  timeline: arrayOf(
    shape({
      title: string,
      subGroups: arrayOf(
        shape({
          title: string,
          milestones: arrayOf(
            shape({
              name: string,
              color: string,
              description: string,
              image: object,
              imagePosition: string,
              video: shape({
                start: string,
                videoId: string
              }),
              audio: shape({
                url: string,
                visual: bool
              }),
              callToActionLabel: string,
              callToActionLink: string
            })
          )
        })
      )
    })
  )
}

export default Timeline
