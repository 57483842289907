import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@/component/Primitive/Grid'
import Container from '@/component/Primitive/Container'
import filterChildComponents from '@/lib/child-component-filterer'

const Row = (props) => {
  const { row, children } = props

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child)
  )

  const childComponents = filterChildComponents(childrenWithProps)
  if (!childComponents.length) return null

  const isFullWidth = row.attributes && row.attributes[0] === 'wide'

  return (
    <>
      <Container
        center
        gutter={!isFullWidth}
        size={isFullWidth ? 'full' : 'huge'}
      >
        <Grid
          flex
          mobileFlexReverse
          gutter={row.cols.length > 1 ? 'large' : null}
        >
          {childComponents}
        </Grid>
      </Container>
    </>
  )
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default Row
