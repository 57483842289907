import MediaList from '@/component/Common/MediaList'
import React from 'react'
import { string, array } from 'prop-types'

const ArticleList = ({ articles, title }) => {
  if (!articles) return null
  return <MediaList items={articles} title={title} />
}

ArticleList.propTypes = {
  articles: array,
  title: string
}

export default ArticleList
