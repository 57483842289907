import React from 'react'
import { string, arrayOf, shape } from 'prop-types'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Type from '@/component/Primitive/Type'

import styles from './ContactDetails.module.scss'
import SmartLink from '@/component/Primitive/SmartLink'

const buildName = (contact) => {
  if (contact.name && contact.job) return contact.name + ', ' + contact.job
  return contact.name
}

const ContactDetails = ({ contacts, title }) => {
  const isDark = useDarkContext()
  return (
    <div className={classNames(styles.ContactDetails, isDark && styles.isDark)}>
      <Type size="display0" as="h3" color="teal" className={styles.Title}>
        {title}
      </Type>
      {contacts &&
        contacts.map((contact, index) => {
          const name = buildName(contact)
          const contactDetails = [
            { type: 'tel', content: contact.phone || '' },
            { type: 'mailto', content: contact.email || '' },
            { type: 'tel', content: contact.secondaryPhone || '' }
          ]
          return (
            <div
              className={styles.Wrapper}
              key={`ContactDetail-${index}-${contact.name}`}
            >
              <Type size="display0">{name}</Type>
              <div className={styles.DetailWrapper}>
                {contactDetails &&
                  contactDetails.map((detail, i) => {
                    if (!detail.content) return null
                    return (
                      <Type
                        size="base"
                        className={styles.ContactDetail}
                        key={`ContactDetail-${i}-${detail.type}`}
                      >
                        <SmartLink href={`${detail.type}:${detail.content}`}>
                          {detail.content}
                        </SmartLink>
                      </Type>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

ContactDetails.propTypes = {
  title: string.isRequired,
  contacts: arrayOf(
    shape({
      name: string.isRequired,
      telephone: string,
      email: string,
      job: string
    })
  )
}

export default ContactDetails
