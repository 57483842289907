import React from 'react'
import PropTypes from 'prop-types'

import CheckControlGroup from '@/component/Primitive/CheckControlGroup'
import CheckControl from '@/component/Primitive/CheckControl'

const StandardControlField = ({
  showRequired = true,
  required,
  label,
  responses,
  name,
  handleOnChange,
  checkType,
  ...other
}) => {
  return (
    <div>
      {responses && responses.length > 0 ? (
        <CheckControlGroup name={name} a11yLabel="Checkboxes">
          {responses &&
            responses.map((response, i) => {
              return (
                <CheckControl
                  type={checkType}
                  key={i}
                  name={name}
                  value={response.response}
                  onChange={handleOnChange}
                >
                  {response.response}
                </CheckControl>
              )
            })}
        </CheckControlGroup>
      ) : (
        <div>
          <CheckControl
            type={checkType}
            name={name}
            value="checked"
            onChange={handleOnChange}
          >
            Please tick or leave blank.
          </CheckControl>
        </div>
      )}
    </div>
  )
}

StandardControlField.defaultProps = {}

StandardControlField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  checkType: PropTypes.string,
  name: PropTypes.string.isRequired,
  responses: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired
}

export default StandardControlField
