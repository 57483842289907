import imageProperties from '../lib/image-properties'

const createQuery = () => `
... on DownloadWidget {
  orientation
  listStyle
  title
  description
  downloads {
    downloadUrl
    title
    description
    callToAction
    downloadSize
    downloadExtension
    images {
      thumbnail_7x10_400 {
        ${imageProperties}
      }
      thumbnail_7x10_188 {
        ${imageProperties}
      }
      thumbnail_7x10_306 {
        ${imageProperties}
      }
      thumbnail_16x9_750 {
        ${imageProperties}
      }
    }

  }
}
`
export default createQuery
