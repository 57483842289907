import React from 'react'
import { string } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import Prose from '@/component/Primitive/Prose'

const TextWidget = (props) => {
  const { html } = props
  const isDark = useDarkContext()

  return (
    <>
      <Prose html={html} inverse={isDark} />
    </>
  )
}

TextWidget.propTypes = {
  html: string
}

export default TextWidget
