import React from 'react'
import { array, string } from 'prop-types'
import useMedia from '@/lib/use-media'

import Slide from './component/Slide'
import Carousel from '@/component/Common/Carousel'

import styles from './LogoCarousel.module.scss'

const LogoCarousel = ({ title, images }) => {
  const isTablet = useMedia('(max-width: 960px)')
  const isPhone = useMedia('(max-width: 550px)')
  const groupCells = isTablet ? (isPhone ? 1 : 2) : 3
  return (
    <Carousel
      className={styles.LogoCarousel}
      groupCells={groupCells}
      title={title}
      titleType="display1"
      hideMobileControls
    >
      {images.map((item, i) => {
        const imageSrc =
          item.images &&
          item.images.logo_5x3_500 &&
          item.images.logo_5x3_500[0] &&
          item.images.logo_5x3_500[0].url
        return (
          <Slide
            title={item.title}
            link={item.link}
            image={{
              src: imageSrc
            }}
            key={`LogoCarousel-${item.title}-${i}`}
          />
        )
      })}
    </Carousel>
  )
}
LogoCarousel.propTypes = {
  title: string,
  images: array
}

export default LogoCarousel
