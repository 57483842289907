import React from 'react'
import PropTypes from 'prop-types'

import TextControl from '@/component/Primitive/TextControl'

const StandardControlField = ({
  showRequired = true,
  required,
  error,
  label,
  responses,
  handleOnChange,
  type,
  wordLimit,
  name,
  ...other
}) => {
  const isTextArea = type === 'textarea'
  return (
    <>
      <TextControl
        name={name}
        multiLine={isTextArea}
        rows={5}
        onChange={(e) => handleOnChange(e, wordLimit)}
      />
      {wordLimit && wordLimit > 0 && `Word limit of ${wordLimit}`}
    </>
  )
}

StandardControlField.defaultProps = {}

StandardControlField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  wordLimit: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  responses: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired
}

export default StandardControlField
