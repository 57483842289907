const createQuery = () => `
... on FaqWidget {
  title
  description
  faqs {
   question
   answer
  }
}
`
export default createQuery
