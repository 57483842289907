import imageProperties from '../lib/image-properties'

const createQuery = () => `
  ... on InlineImageWidget {
    inlineImageImages: images {
      crop
      title
      images {
        inline_16x9_1920 {
          ${imageProperties}
        }
        inline_1x1_326 {
          ${imageProperties}
        }
        inline_16x9_577 {
          ${imageProperties}
        }
        inline_1x1_577 {
          ${imageProperties}
        }
        inline_free_577 {
          ${imageProperties}
        }
        inline_free {
          ${imageProperties}
        }
      }
    }
  }
`
export default createQuery
