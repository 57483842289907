import React from 'react'
import propTypes from 'prop-types'
import FeaturedMedia from '@/component/Common/FeaturedMedia'

const UpcomingReleaseWidget = ({ release }) => {
  if (!release) return null
  const props = {
    mediaTitle: release.title,
    title: 'Latest Release',
    rating: release.classification && release.classification.toUpperCase(),
    shortFormInsight: release.shortFormInsight,
    releaseDate: release.releaseDate,
    img: {
      src: release.images && release.images._573 && release.images._573.url,
      alt: release && release.title
    },
    summary: release.synopsis,
    url: `/release/${release && release.slug}`
  }

  return <FeaturedMedia {...props} />
}

UpcomingReleaseWidget.propTypes = {
  release: propTypes.object
}

export default UpcomingReleaseWidget
