import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import downloadPropTypes from '@/shape/download'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import Grid from '@/component/Primitive/Grid'
import Type from '@/component/Primitive/Type'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'
import SmartLink from '@/component/Primitive/SmartLink'
import DownloadItem from './component/DownloadItem'
import Expander from '@/component/Primitive/Expander'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import { saDownloadEvent } from '@/lib/simple-analytics-events'

import styles from './Downloads.module.scss'

const Downloads = ({
  title,
  description,
  downloads,
  orientation,
  listStyle
}) => {
  const isDark = useDarkContext()
  if (!downloads || !downloads.length) return null
  const featured = downloads && downloads[0]
  const remainingDownloads = downloads.slice(1)
  const featuredImage = featured && featured.images
  const featuredPortraitImage =
    featuredImage.thumbnail_7x10_400 && featuredImage.thumbnail_7x10_400[0]
  const featuredLandscapeImage =
    featuredImage.thumbnail_16x9_750 && featuredImage.thumbnail_16x9_750[0]
  const formattedExtension =
    featured &&
    featured.downloadExtension &&
    featured.downloadExtension.toUpperCase()

  const handleClick = (title) => {
    saDownloadEvent(`Download: ${title}`)
  }

  return (
    <div className={classNames(styles.Downloads, isDark && styles.isDark)}>
      {title && (
        <Type
          as="h3"
          size="display0"
          className={styles.Title}
          color={isDark ? 'white' : 'teal'}
        >
          {title}
        </Type>
      )}
      {description && (
        <Type as="p" size="base-large" className={styles.Description}>
          {description}
        </Type>
      )}
      <div className={styles.Wrapper}>
        {featured && (
          <div
            className={classNames(
              styles.FeaturedDownload,
              orientation === 'No Image' && styles.FeaturedDownload_noImage
            )}
          >
            {featuredImage && (
              <div className={styles.FeaturedImageWrapper}>
                <ResponsiveImage
                  className={styles.FeaturedImage}
                  alt={(featuredImage && featuredImage.alt) || ''}
                  src={
                    (orientation === 'Portrait' && featuredPortraitImage.url) ||
                    (orientation === 'Landscape' && featuredLandscapeImage.url)
                  }
                />
              </div>
            )}
            <div className={styles.DownloadBody}>
              {featured.title && (
                <Type as="h3" size="display1" className={styles.DownloadTitle}>
                  {featured.title}
                </Type>
              )}
              {featured.description && (
                <Type as="p" size="base" className={styles.DownloadDescription}>
                  {featured.description}
                </Type>
              )}
              {featured.downloadUrl && featured.callToAction && (
                <SmartLink
                  onClick={() => handleClick(featured.title)}
                  href={featured.downloadUrl}
                  className={styles.DownloadLink}
                >
                  <ButtonStandard>
                    <Type as="span" size="base" bold>
                      {featured.callToAction}
                    </Type>
                  </ButtonStandard>
                </SmartLink>
              )}
              {formattedExtension && featured.downloadSize && (
                <Type as="p" size="base" className={styles.FileInfo}>
                  {formattedExtension} | {featured.downloadSize}
                </Type>
              )}
            </div>
          </div>
        )}
        {remainingDownloads && remainingDownloads.length !== 0 && (
          <Expander
            title="See more"
            className={styles.RemainingDownloadsExpander}
            classNames={{
              title: styles.RemainingDownloadsExpander_title,
              content: styles.RemainingDownloadsExpander_content
            }}
          >
            {listStyle === 'grid' ? (
              <Grid gutter="small" className={styles.DownloadsGrid}>
                {remainingDownloads.map((download, i) => {
                  const downloadImage =
                    download &&
                    download.images.thumbnail_7x10_306 &&
                    download.images.thumbnail_7x10_306[0]
                  const image = {
                    alt: (downloadImage && downloadImage.alt) || '',
                    src: (downloadImage && downloadImage.url) || ''
                  }
                  return (
                    <Grid.Item
                      width={[1, 1, 1 / 2, 1 / 5]}
                      key={`Download-${download.title}-${i}`}
                    >
                      <DownloadItem
                        onClick={() => handleClick(download.title)}
                        title={download.title}
                        fileSize={download.downloadSize}
                        downloadText={download.callToAction}
                        fileType={download.downloadExtension.toUpperCase()}
                        img={image}
                        url={download.downloadUrl}
                      />
                    </Grid.Item>
                  )
                })}
              </Grid>
            ) : (
              <div className={styles.DownloadsList}>
                {remainingDownloads.map((download, i) => {
                  return (
                    <div
                      className={classNames(
                        styles.DownloadsList_item,
                        i % 2 === 0 && styles.DownloadsList_itemOdd
                      )}
                      key={i}
                    >
                      <Type bold className={styles.DownloadsList_title}>
                        {download.title}
                      </Type>
                      <SmartLink
                        onClick={() => handleClick(download.title)}
                        href={download.downloadUrl}
                        className={styles.DownloadsList_link}
                      >
                        <Type>Download</Type>
                      </SmartLink>
                    </div>
                  )
                })}
              </div>
            )}
          </Expander>
        )}
      </div>
    </div>
  )
}

Downloads.defaultProps = {
  gridRight: false
}

Downloads.propTypes = {
  title: string,
  description: string,
  orientation: string,
  listStyle: string,
  downloads: arrayOf(shape(downloadPropTypes))
}

export default Downloads
