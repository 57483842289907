import React from 'react'
import { node, string, bool, shape, object } from 'prop-types'
import imageShape from '@/shape/image'
import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import { useRatingContext } from '@/component/Context/RatingContext'

import Container from '../../Container'
import SmartLink from '../../SmartLink'
import Type from '../../Type'
import VisuallyHidden from '../../VisuallyHidden'
import Surround from '@/component/Primitive/Surround'
import ResponsiveBackgroundImage from '@/component/Primitive/ResponsiveBackgroundImage'
import { Circle } from './Shapes'
import RatingColor from '@/component/Common/RatingColor'

const SplitPanel = ({
  children,
  displayDensity,
  splitStyle,
  link,
  reverse,
  img,
  tabletImg,
  mobileImg,
  shadow,
  rounded,
  overlayed,
  underlayed,
  shiftUp,
  fileSize,
  fileType,
  className,
  styles
}) => {
  const Wrapper = link ? SmartLink : 'div'
  const contextRating = useRatingContext()
  const derivedRating = contextRating && contextRating.toLowerCase()
  const isDark = useDarkContext()

  const splitStyleMap = {
    u: 'slanted',
    pg: 'slanted',
    '12a': 'rounded',
    '12': 'rounded',
    '15': 'rounded',
    '18': 'rounded'
  }

  const derivedSplitStyle = splitStyle || splitStyleMap[derivedRating]

  return (
    <Surround
      className={classNames(
        styles.SplitPanel,
        displayDensity && styles[displayDensity],
        reverse && styles.reverse,
        overlayed && styles.overlayed,
        underlayed && styles.underlayed,
        isDark && styles.isDark,
        shiftUp && styles['shift-up'],
        className
      )}
      shadow={shadow}
      rounded={rounded}
    >
      <ResponsiveBackgroundImage
        Component={Wrapper}
        imageConfig={{ 0: mobileImg.src, 550: tabletImg.src, 960: img.src }}
        to="/article"
        as={link}
        className={classNames('Background', styles.Background)}
      >
        <VisuallyHidden>{img.alt}</VisuallyHidden>
      </ResponsiveBackgroundImage>

      <Container gutter center size="huge" className={styles.SplitWrapper}>
        <RatingColor
          property="background-color"
          className={styles.MobileBackground}
        />
        {derivedSplitStyle === 'slanted' && (
          <RatingColor property="background-color">
            <div className={styles.Slant} />
          </RatingColor>
        )}
        {derivedSplitStyle === 'rounded' && (
          <RatingColor property="fill">
            <Circle className={styles.Circle} />
          </RatingColor>
        )}
        <RatingColor property="background-color">
          <div className={styles.Inner}>
            <Container className={styles.Content}>{children}</Container>
            <div className={styles.LinkWrapper}>
              {fileType && fileSize && link && (
                <Type size="base-large" className={styles.FileInfo}>
                  {fileType} | {fileSize}
                </Type>
              )}
              {link && (
                <Type size="base-large">
                  {(!fileType || !fileSize) && (
                    <SmartLink className={styles.Link} to="/article" as={link}>
                      Read more
                    </SmartLink>
                  )}
                  {fileType && fileSize && (
                    <a
                      className={styles.Link}
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download file
                    </a>
                  )}
                </Type>
              )}
            </div>
          </div>
        </RatingColor>
      </Container>
    </Surround>
  )
}

SplitPanel.defaultProps = {
  displayDensity: 'standard'
}

SplitPanel.propTypes = {
  children: node.isRequired,
  displayDensity: string,
  img: shape(imageShape).isRequired,
  tabletImg: shape(imageShape).isRequired,
  mobileImg: shape(imageShape).isRequired,
  reverse: bool,
  link: string,
  shadow: bool,
  rounded: bool,
  overlayed: bool,
  underlayed: bool,
  shiftUp: bool,
  fileType: string,
  fileSize: string,
  className: string,
  splitStyle: string,
  styles: object
}

export default SplitPanel
