import React from 'react'
import classNames from 'classnames'
import { array } from 'prop-types'
import SmartLink from '../../Primitive/SmartLink'
import Type from '@/component/Primitive/Type'
import { useDarkContext } from '@/component/Context/DarkContext'
import styles from '@/component/Common/ArticleHeader/theme/BBFCArticleHeader.module.scss'
import Container from '@/component/Primitive/Container'

const BreadcrumbWidget = (props) => {
  const { sections } = props
  const isDark = useDarkContext()
  return (
    <>
      <div
        className={classNames(
          styles.BreadCrumbWrapper,
          isDark && styles.isDark
        )}
      >
        <Container gutter>
          {sections &&
            sections.map((breadCrumb, i) => (
              <Type
                key={`Breadcrumb-${breadCrumb.name}-${i}`}
                as="span"
                size="base"
                bold
                className={styles.BreadCrumb}
              >
                <SmartLink to="/section" as={breadCrumb.fullUrlPath}>
                  {breadCrumb.name}
                </SmartLink>
              </Type>
            ))}
        </Container>
      </div>
    </>
  )
}

BreadcrumbWidget.propTypes = {
  sections: array
}

export default BreadcrumbWidget
