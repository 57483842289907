import React from 'react'
import { string, arrayOf, shape } from 'prop-types'

import styles from './PageContents.module.scss'
import Expander from '@/component/Primitive/Expander'

const PageContentsWidget = ({ items }) => {
  return (
    <Expander
      className={styles.Contents}
      title="Contents"
      classNames={{
        icon: styles.ToggleIcon,
        title: styles.Title
      }}
    >
      <div className={styles.ContentsLinksContainer}>
        {items.map((item, i) => (
          <a key={i} href={`#${item.link}`} className={styles.ContentsLinks}>
            {item.heading}
          </a>
        ))}
      </div>
    </Expander>
  )
}

PageContentsWidget.propTypes = {
  items: arrayOf(
    shape({
      heading: string.isRequired,
      link: string.isRequired
    })
  )
}

export default PageContentsWidget
