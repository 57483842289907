import React from 'react'
import PropTypes from 'prop-types'
import Field from '../Field'

const FormBuilderQuestion = ({
  question,
  handleOnChange,
  data = {},
  error = {},
  id
}) => {
  return (
    <Field
      handleOnChange={handleOnChange}
      name={`question-${id}`}
      value={data[`question-${id}`] || ''}
      error={error[`question-${id}`]}
      type={question.inputType}
      label={question.question}
      required={question.required}
      assistance={question.description}
      responses={question.responses}
      wordLimit={question.wordLimit}
    />
  )
}

FormBuilderQuestion.propTypes = {
  question: PropTypes.object,
  handleOnChange: PropTypes.func.isRequired,
  data: PropTypes.object,
  error: PropTypes.object,
  id: PropTypes.number
}

export default FormBuilderQuestion
