import React, { Fragment } from 'react'
import { arrayOf, shape, string } from 'prop-types'

import { DarkContextConsumer } from '@/component/Context/DarkContext'
import Type from '@/component/Primitive/Type'
import AnswerBox from '@/component/Common/AnswerBox'
import CheckControlGroup from '@/component/Primitive/CheckControlGroup'
import CheckControl from '@/component/Primitive/CheckControl'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import FieldTemplate from '@/component/Primitive/FieldTemplate'

import styles from './Quiz.module.scss'

class Quiz extends React.Component {
  constructor(props) {
    super(props)
    const { quiz } = this.props
    const total =
      quiz && quiz[0] && quiz[0].questions && quiz[0].questions.length
    this.state = {
      data: {},
      validationError: {},
      submitted: false,
      score: 0,
      total
    }
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange(e) {
    const targetValue = e.currentTarget.value
    this.setState({
      data: { ...this.state.data, [e.currentTarget.name]: targetValue }
    })
    Array.from(e.currentTarget.parentElement.children).forEach((sib) =>
      sib.classList.remove(styles.Selected)
    )
    e.currentTarget.classList.add(styles.Selected)
  }

  calculateScore() {
    const { data } = this.state
    const { quiz } = this.props
    const { questions } = quiz[0] || {}
    const correctAnswers =
      questions &&
      questions.filter((question, index) => {
        const items = question.items
        const answer = items && items.find((item) => item.isCorrectAnswer)
        return answer && answer.label === data[`question-${index + 1}`]
      }).length
    this.setState({ score: correctAnswers })
  }

  checkFieldsCompleted() {
    const { data } = this.state
    const { quiz } = this.props
    const { questions } = quiz && quiz[0]
    const validationError = {}
    questions &&
      questions.forEach((question, index) => {
        if (!data[`question-${index + 1}`]) {
          validationError[`question-${index + 1}`] =
            'You missed out this question!'
        }
      })
    if (Object.keys(validationError).length > 0) {
      this.setState({ validationError })
      return true
    }
  }

  clearErrors() {
    this.setState({ validationError: {} })
  }

  handleOnSubmit(e) {
    this.clearErrors()
    if (this.checkFieldsCompleted()) return
    this.calculateScore()
    this.setState({
      submitted: true
    })
  }

  render() {
    const { quiz } = this.props
    const { validationError, submitted, score, total } = this.state
    const { title, description, questions, resultMessage } = quiz[0] || {}
    return (
      <DarkContextConsumer>
        {(isDark) => (
          <div className={isDark ? styles.isDark : undefined}>
            <Type as="h2" size="title" bold className={styles.Title}>
              {title}
            </Type>
            <Type as="h3" size="subtitle" className={styles.Description}>
              {description}
            </Type>
            {questions &&
              questions.map((quest, index) => {
                const { question, answerText, items } = quest || {}
                const answer =
                  items && items.find((item) => item.isCorrectAnswer)
                const displayAsGrid =
                  items &&
                  items.some(
                    (item) =>
                      item &&
                      item.images &&
                      item.images.main_16x9_340 &&
                      item.images.main_16x9_340[0] &&
                      item.images.main_16x9_340[0].url
                  )
                return (
                  <div key={index}>
                    <Type
                      as="p"
                      size="base-large"
                      className={styles.QuestionTitle}
                    >
                      {`Question ${index + 1}:`}
                    </Type>
                    <Type as="p" className={styles.Question}>
                      {question}
                    </Type>

                    <FieldTemplate
                      template="check"
                      required
                      controlName={question}
                      status={
                        validationError[`question-${index + 1}`] && 'error'
                      }
                      feedback={validationError[`question-${index + 1}`]}
                    >
                      <CheckControlGroup
                        a11yLabel={question}
                        row={displayAsGrid}
                      >
                        {items &&
                          items.map((item, i) => {
                            const url =
                              item &&
                              item.images &&
                              item.images.main_16x9_340 &&
                              item.images.main_16x9_340[0] &&
                              item.images.main_16x9_340[0].url
                            const image = { src: url }
                            return (
                              <Fragment key={i}>
                                {url && (
                                  <button
                                    onClick={this.handleOnChange}
                                    value={item.label}
                                    name={`question-${index + 1}`}
                                    className={styles.SelectImage}
                                  >
                                    <img src={image.src} alt={image.alt} />
                                    {item && item.label && (
                                      <Type
                                        size="base"
                                        className={styles.Option}
                                      >
                                        {item && item.label}
                                      </Type>
                                    )}
                                  </button>
                                )}
                                {!url && (
                                  <CheckControl
                                    type="radio"
                                    value={item.label}
                                    name={`question-${index + 1}`}
                                    onChange={this.handleOnChange}
                                    key={`Option-${i}-${item.label}`}
                                  >
                                    <Type size="base" className={styles.Option}>
                                      {item && item.label}
                                    </Type>
                                  </CheckControl>
                                )}
                              </Fragment>
                            )
                          })}
                      </CheckControlGroup>
                    </FieldTemplate>
                    {submitted && answer && (
                      <AnswerBox
                        answer={answer && answer.label}
                        answerText={answerText}
                        isDark={isDark}
                      />
                    )}
                  </div>
                )
              })}
            {submitted && (
              <>
                <Type
                  as="h3"
                  size="title-small"
                  bold
                  className={styles.Results}
                >
                  Results
                </Type>
                <Type as="p" className={styles.ResultsText}>
                  {`${resultMessage || ''} You scored:`}
                  &nbsp;
                  <span
                    className={styles.Score}
                  >{`${score} out of ${total}.`}</span>
                </Type>
              </>
            )}
            {!submitted && (
              <ButtonStandard
                className={styles.Button}
                type="submit"
                value="Submit"
                name="Submit"
                onClick={this.handleOnSubmit}
              >
                Submit
              </ButtonStandard>
            )}
          </div>
        )}
      </DarkContextConsumer>
    )
  }
}

Quiz.propTypes = {
  quiz: arrayOf(
    shape({
      title: string,
      description: string,
      resultsMessage: string
    })
  )
}

export default Quiz
