import React from 'react'
import { array } from 'prop-types'

import BioGrid from '@/component/Common/BioGrid/'

const BioGridWidget = ({ bios }) => {
  const formattedBios =
    bios &&
    bios.map((bio) => {
      const image =
        bio &&
        bio.images &&
        bio.images.profile_1x1_300 &&
        bio.images.profile_1x1_300[0]

      return {
        name: bio.name,
        title: bio.jobTitle,
        content: bio.biography,
        img: {
          src: image && image.url,
          alt: image && image.alt,
          srcSet: []
        }
      }
    })
  return <BioGrid items={formattedBios} />
}

BioGridWidget.propTypes = {
  bios: array
}

export default BioGridWidget
