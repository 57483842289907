import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Html.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const HtmlWidget = (props) => {
  const { html } = props
  const isDark = useDarkContext()

  return (
    <div
      className={classNames(
        'widget widget--html',
        styles.Html,
        isDark && styles.isDark
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

HtmlWidget.propTypes = {
  html: PropTypes.string
}

export default HtmlWidget
