import React, { Fragment } from 'react'
import { array, object, node, bool } from 'prop-types'
import widgetFactory from '../../Widgets'
import Surround from '@/component/Primitive/Surround'
import ResponsiveSpacer from '@/component/Primitive/ResponsiveSpacer'
import { useDarkContext } from '@/component/Context/DarkContext'

const WidgetWrapper = ({ children, shouldWrapWithSurround, isDark }) => {
  return shouldWrapWithSurround ? (
    <Surround isDark={isDark} rounded solid shadow wide>
      {children}
    </Surround>
  ) : (
    <>{children}</>
  )
}

WidgetWrapper.propTypes = {
  children: node,
  isDark: bool,
  shouldWrapWithSurround: bool
}

const WidgetArea = ({
  widgets,
  article,
  section,
  instance,
  pageData,
  column,
  row,
  includeSurround,
  smallSpacing
}) => {
  const isDark = useDarkContext()
  if (!widgets) return null

  const isFullWidthRow = row && row.attributes && row.attributes[0] === 'wide'
  const isTwoThirdsColumn = column && column.width && column.width === 2
  const isOneThirdsColumn = column && column.width && column.width === 1

  const shouldWrapWithSurround =
    includeSurround && !isFullWidthRow && !isTwoThirdsColumn

  // Only render widgets which have content to show
  const componentsToRender = widgets
    .map((widget) => {
      const Component = widgetFactory[widget.type]
      if (!Component) return null
      const ComponentToCheck = Component({
        ...widget,
        article,
        section,
        instance,
        pageData
      })
      if (!ComponentToCheck) return null
      return ComponentToCheck
    })
    .filter((w) => w)

  if (!componentsToRender.length) return null

  return (
    <>
      {componentsToRender.map((c, index) => {
        const Component = () => c
        return (
          <Fragment key={index}>
            <WidgetWrapper
              shouldWrapWithSurround={shouldWrapWithSurround}
              isDark={isDark}
            >
              <Component />
            </WidgetWrapper>
            {index !== componentsToRender.length - 1 && (
              <ResponsiveSpacer
                small={smallSpacing || isOneThirdsColumn || isTwoThirdsColumn}
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

WidgetArea.propTypes = {
  widgets: array,
  article: object,
  section: object,
  instance: object,
  pageData: object,
  column: object,
  row: object,
  includeSurround: bool,
  smallSpacing: bool
}

export default WidgetArea
