import React from 'react'

import { useThemeContext } from '@/component/Context/ThemeContext'
import FilmRatingStrip from '@/component/Common/FilmRatingStrip'
import getRatings from '@/component/Common/RatingsSubNavigation/get-ratings'

const RatingsBanner = () => {
  const theme = useThemeContext()
  const ratings = getRatings(theme)
  return <FilmRatingStrip ratings={ratings} />
}

export default RatingsBanner
