const widgets = {
  html: require('./Html').default,
  featuredArticle: require('./FeaturedArticle').default,
  heroArticle: require('./HeroArticle').default,
  text: require('./Text').default,
  rateATrailer: require('./RateATrailer').default,
  ratingsNavigation: require('./RatingsNavigation').default,
  subNavigation: require('./SubNavigation').default,
  inlineImage: require('./InlineImage').default,
  audio: require('./Audio').default,
  video: require('./Video').default,
  articleGrid: require('./ArticleGrid').default,
  relatedArticles: require('./RelatedArticles').default,
  download: require('./Download').default,
  articleDownload: require('./ArticleDownload').default,
  breadcrumb: require('./Breadcrumb').default,
  faq: require('./Faq').default,
  articleLayoutHeader: require('./ArticleLayoutHeader').default,
  articleLayoutBody: require('./ArticleLayoutBody').default,
  relatedWidgets: require('./RelatedWidgets').default,
  standfirst: require('./Standfirst').default,
  share: require('./Share').default,
  filmInformation: require('./FilmInformation').default,
  featuredDownload: require('./FeaturedDownload').default,
  contacts: require('./Contacts').default,
  articleList: require('./ArticleList').default,
  paginatedArticleList: require('./PaginatedArticleList').default,
  lookOutFor: require('./LookOutFor').default,
  timeline: require('./Timeline').default,
  mailchimp: require('./Mailchimp').default,
  manualSubNavigation: require('./ManualSubNavigation').default,
  poll: require('./Poll').default,
  form: require('./Form').default,
  ratingsBanner: require('./RatingsBanner').default,
  quiz: require('./Quiz').default,
  recentlyRated: require('./RecentlyRated').default,
  bioGrid: require('./BioGrid').default,
  releaseTriplet: require('./ReleaseTriplet').default,
  logoCarousel: require('./LogoCarousel').default,
  upcomingRelease: require('./UpcomingRelease').default,
  pageContents: require('./PageContents').default,
  progressScroller: require('./ProgressScroller').default,
  spotTheIssue: require('./SpotTheIssues').default
}

module.exports = widgets
