const createQuery = () => `
... on FormWidget {
  downloadButtonText
  downloadUrl
  form {
    _id
    title
    thankyouMessage
    account
    questions {
      question
      required
      inputType
      wordLimit
      width
      responses {
        response
      }
    }
  }
}
`
export default createQuery
