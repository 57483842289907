import React from 'react'
import MediaGrid from '../../Common/MediaGrid'
import { array } from 'prop-types'

const RecentlyRatedWidget = ({ releases }) => {
  if (!releases || !releases.length) return null
  const media = releases.map((release) => ({
    rating: release.classification,
    title: release.title,
    type: release.mediaType,
    shortFormInsight: release.shortFormInsight,
    classificationDate: release.classificationDate,
    img: {
      src: release.images && release.images._451 && release.images._451.url,
      alt: release.title,
      srcSet: []
    },
    url: `/release/${release.slug}`
  }))

  return (
    <MediaGrid
      media={media}
      title="Recently Rated"
      linkText="View More"
      linkTo="/recently-rated"
    />
  )
}

RecentlyRatedWidget.propTypes = {
  releases: array
}

export default RecentlyRatedWidget
