import PropTypes from 'prop-types'
import React from 'react'
import WidgetArea from '../../Common/WidgetArea'

const ArticleLayoutBody = ({ article, section, instance, pageData }) => {
  const articleBodyWidgets = article && article.body && article.body.widgets
  return (
    <WidgetArea
      widgets={articleBodyWidgets}
      article={article}
      section={section}
      instance={instance}
      pageData={pageData}
      smallSpacing
    />
  )
}

ArticleLayoutBody.propTypes = {
  section: PropTypes.object,
  article: PropTypes.object,
  instance: PropTypes.object,
  pageData: PropTypes.object
}

export default ArticleLayoutBody
