import React from 'react'
import propTypes from 'prop-types'
import Faq from '@/component/Common/Faq'

const FaqWidget = (props) => {
  const { faqs, title, description } = props
  return (
    <>
      <Faq faqs={faqs} title={title} description={description} />
    </>
  )
}

FaqWidget.propTypes = {
  faqs: propTypes.array,
  title: propTypes.string,
  description: propTypes.string
}

export default FaqWidget
