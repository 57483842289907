import React from 'react'
import SplitPanel from '../../Primitive/SplitPanel'
import Type from '../../Primitive/Type'
import propTypes from 'prop-types'

const FeaturedDownloadWidget = ({
  title,
  description,
  downloadUrl,
  downloadExtension,
  downloadSize,
  featuredDownloadImages
}) => {
  const getImage = (size) => {
    return (
      featuredDownloadImages &&
      featuredDownloadImages[`main_16x9_${size}`] &&
      featuredDownloadImages[`main_16x9_${size}`][0]
    )
  }

  const mobileImg = {
    url: getImage(550).url,
    alt: 'caption'
  }

  const img = {
    url: getImage(1000).url,
    alt: 'caption'
  }

  const upperCaseExtension =
    downloadExtension && downloadExtension.toUpperCase()
  return (
    <>
      <SplitPanel
        img={{ src: img.url, alt: img.alt }}
        tabletImg={{ src: mobileImg.url, alt: mobileImg.alt }}
        mobileImg={{ src: mobileImg.url, alt: mobileImg.alt }}
        link={downloadUrl}
        fileType={upperCaseExtension}
        fileSize={downloadSize}
      >
        <>
          <Type as="h2" size="title">
            {title}
          </Type>
          <Type as="p" size="base-large">
            {description}
          </Type>
        </>
      </SplitPanel>
    </>
  )
}

FeaturedDownloadWidget.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  downloadUrl: propTypes.string,
  downloadExtension: propTypes.string,
  downloadSize: propTypes.string,
  featuredDownloadImages: propTypes.object
}

export default FeaturedDownloadWidget
