import React from 'react'
import { string, shape, bool } from 'prop-types'
import imagePropTypes from '@/shape/image'
import classnames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'
import moment from 'moment'

import Type from '@/component/Primitive/Type'
import SmartLink from '@/component/Primitive/SmartLink'
import AgeRating from '@/component/Primitive/AgeRating'
import ResponsiveImage from '@/component/Primitive/ResponsiveImage'

import styles from './MediaItem.module.scss'

const MediaItem = ({
  headline,
  fullUrlPath,
  displayDate,
  sell,
  rating,
  category,
  img,
  showImage
}) => {
  const isDark = useDarkContext()
  const isValidDate = moment(displayDate).isValid()
  const formattedPublishedDate = isValidDate
    ? moment(displayDate).format('Do MMMM YYYY')
    : ''
  return (
    <SmartLink
      to="/article"
      as={fullUrlPath}
      className={classnames(styles.MediaItem, isDark && styles.isDark)}
    >
      {rating && (
        <div className={styles.RatingWrapper}>
          <AgeRating rating={rating} className={styles.Rating} />
        </div>
      )}
      {img && showImage && (
        <div className={styles.ImageWrapper} ratio={1}>
          <ResponsiveImage className={styles.Image} src={img.url} />
        </div>
      )}
      <div className={styles.Wrapper}>
        <Type as="h3" size="title" className={styles.Title}>
          {headline}
        </Type>
        {sell && (
          <Type as="p" size="base" className={styles.Description}>
            {sell}
          </Type>
        )}
        {isValidDate && category === 'News' && (
          <Type as="h3" size="base" className={styles.PublishedDate}>
            Published {formattedPublishedDate}
          </Type>
        )}
      </div>
    </SmartLink>
  )
}

MediaItem.propTypes = {
  showImage: bool,
  headline: string.isRequired,
  fullUrlPath: string.isRequired,
  displayDate: string,
  sell: string,
  category: string,
  rating: string,
  img: shape(imagePropTypes)
}

export default MediaItem
