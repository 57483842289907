import React from 'react'
import { string, bool } from 'prop-types'
import Type from '@/component/Primitive/Type'

import styles from './SoundcloudEmbed.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const SoundcloudEmbed = ({
  url,
  title,
  height,
  autoPlay,
  hideRelated,
  showComments,
  showUser,
  showReposts,
  visual
}) => {
  const src = `https://w.soundcloud.com/player/?url=${url}&amp;auto_play=${autoPlay}&amp;hide_related=${hideRelated}&amp;show_comments=${showComments}&amp;show_user=${showUser}&amp;show_reposts=${showReposts}&amp;${
    visual ? `visual=${visual}` : ''
  }`

  const isDark = useDarkContext()

  return (
    <div>
      {title && (
        <Type
          className={styles.Title}
          size="base-large"
          bold
          color={isDark ? 'white' : 'teal'}
        >
          {title}
        </Type>
      )}
      <iframe
        title="Embedded Soundcloud audio"
        width="100%"
        height={visual ? height : 'auto'}
        scrolling="no"
        frameBorder="no"
        src={src}
      />
    </div>
  )
}

SoundcloudEmbed.defaultProps = {
  height: '450px',
  autoPlay: false,
  hideRelated: true,
  showComments: true,
  showUser: true,
  showReposts: false,
  visual: false
}

SoundcloudEmbed.propTypes = {
  height: string,
  title: string,
  url: string.isRequired,
  autoPlay: bool,
  visual: bool,
  showReposts: bool,
  showComments: bool,
  showUser: bool,
  hideRelated: bool
}

export default SoundcloudEmbed
