import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import PropTypes from 'prop-types'
import Timeline from '../../Common/Timeline'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import ResponsiveSpacer from '@/component/Primitive/ResponsiveSpacer'

class PrintableTimeline extends React.Component {
  render() {
    return (
      <div style={{ margin: '150px 75px' }}>
        <Timeline timeline={this.props.timeline} />
      </div>
    )
  }
}

PrintableTimeline.propTypes = {
  timeline: PropTypes.array
}

const TimelineWidget = ({ groups }) => {
  const componentRef = useRef()
  return (
    <>
      <div style={{ display: 'none' }}>
        <PrintableTimeline ref={componentRef} timeline={groups} />
      </div>
      <Timeline timeline={groups} />
      <ResponsiveSpacer />
      <ReactToPrint
        trigger={() => <ButtonStandard>Print</ButtonStandard>}
        content={() => componentRef.current}
      />
    </>
  )
}

TimelineWidget.propTypes = {
  groups: PropTypes.array
}

export default TimelineWidget
