import React from 'react'
import PropTypes from 'prop-types'
import Container from '@/component/Primitive/Container'
import SplitPanel from '@/component/Primitive/SplitPanel'
import Type from '@/component/Primitive/Type'
import { useDarkContext } from '@/component/Context/DarkContext'

const FeaturedArticle = (props) => {
  const isDark = useDarkContext()
  const { title, articles, slanted } = props
  if (!articles || !articles.length) return null

  const firstArticle = articles[0]
  const image =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_1000 &&
    firstArticle.images.hero_5x4_1000[0]
  const tabletImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_5x4_500 &&
    firstArticle.images.hero_5x4_500[0]
  const mobileImage =
    firstArticle &&
    firstArticle.images &&
    firstArticle.images.hero_16x9_550 &&
    firstArticle.images.hero_16x9_550[0]
  const url = image && image.url
  const tabletUrl = tabletImage && tabletImage.url
  const mobileUrl = mobileImage && mobileImage.url
  const fullUrlPath = firstArticle && firstArticle.fullUrlPath

  return firstArticle ? (
    <>
      <Container center size="full">
        <SplitPanel
          splitStyle={slanted ? 'slanted' : ''}
          img={{ src: url }}
          tabletImg={{ src: tabletUrl }}
          mobileImg={{ src: mobileUrl }}
          link={fullUrlPath}
          shadow
          rounded
          displayDensity="comfortable"
        >
          <Type color={isDark ? 'white' : 'teal'} size="display1">
            {title}
          </Type>
          <Type as="h2" size="title">
            {firstArticle.headline}
          </Type>
          <Type as="p" size="base-large">
            {firstArticle.sell}
          </Type>
        </SplitPanel>
      </Container>
    </>
  ) : null
}

FeaturedArticle.propTypes = {
  articles: PropTypes.array,
  title: PropTypes.string,
  slanted: PropTypes.bool
}

export default FeaturedArticle
