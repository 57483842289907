import React from 'react'
import propTypes from 'prop-types'
import Expander from '@/component/Primitive/Expander'
import Prose from '@/component/Primitive/Prose'
import Type from '@/component/Primitive/Type'
import { useDarkContext } from '@/component/Context/DarkContext'

import styles from './Faq.module.scss'

const Faq = (props) => {
  const { faqs, title, description } = props
  const isDark = useDarkContext()
  return (
    <div className={styles.FaqWrapper}>
      <div className={isDark && styles.isDark}>
        <div className={styles.FaqTitle}>
          {title && (
            <Type
              className={styles.FaqTitle}
              size="display1"
              color="teal"
              as="span"
            >
              {title}
            </Type>
          )}
          {description && (
            <Prose className={styles.Content}>{description}</Prose>
          )}
        </div>
        {faqs &&
          faqs.map((faq, i) => {
            if (!faq) return null
            return (
              <Expander
                options={{ spacious: true }}
                title={faq.question}
                key={i}
              >
                <Prose className={styles.FaqContent} html={faq.answer} />
              </Expander>
            )
          })}
      </div>
    </div>
  )
}

Faq.propTypes = {
  faqs: propTypes.array,
  title: propTypes.string,
  description: propTypes.string
}

export default Faq
