import React from 'react'
import { arrayOf, shape, string, func, object, bool } from 'prop-types'
import classNames from 'classnames'

import CheckControlGroup from '@/component/Primitive/CheckControlGroup'
import CheckControl from '@/component/Primitive/CheckControl'
import Type from '@/component/Primitive/Type'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import LoadingSpinner from '@/component/Primitive/Spinner'
import { useDarkContext } from '@/component/Context/DarkContext'
import {
  isError,
  showNotification,
  defaultServerErrorMessage,
  defaultValidationErrorMessage
} from '../../FormBuilder/lib/form-errors'

import styles from './Questions.module.scss'

const Questions = ({
  questions,
  description,
  title,
  handleOnChange,
  handleOnSubmit,
  validationError = {},
  serverError = {},
  required,
  waiting
}) => {
  const isDark = useDarkContext()
  return (
    <>
      <Type as="h4" size="display1" className={classNames(styles.Title)}>
        {title || ''}
      </Type>
      {isError(serverError) && showNotification(defaultServerErrorMessage)}
      {isError(validationError) &&
        showNotification(defaultValidationErrorMessage)}
      <form
        onSumit={handleOnSubmit}
        className={classNames(styles.Questions, isDark && styles.isDark)}
      >
        <Type size="base-large" as="p" className={styles.Description}>
          {description || ''}
        </Type>
        {questions &&
          questions.map((question, i) => (
            <Question
              question={question.question}
              responses={question.responses}
              handleOnChange={handleOnChange}
              required={required}
              validationError={validationError[`question-${i + 1}`]}
              name={`question-${i + 1}`}
              key={`PollQuestion-${question.question}-${i}`}
            />
          ))}
        {waiting ? (
          <LoadingSpinner />
        ) : (
          <ButtonStandard
            className={styles.Button}
            type="submit"
            value="Submit"
            name="Submit"
            onClick={handleOnSubmit}
          >
            Submit
          </ButtonStandard>
        )}
      </form>
    </>
  )
}

Questions.propTypes = {
  validationError: object,
  serverError: object,
  required: object,
  waiting: bool,
  title: string,
  description: string,
  handleOnChange: func.isRequired,
  handleOnSubmit: func.isRequired,
  questions: arrayOf(
    shape({
      question: string,
      options: arrayOf(
        shape({
          option: string
        })
      )
    })
  )
}

const Question = ({
  question,
  responses,
  handleOnChange,
  validationError,
  required,
  name
}) => {
  return (
    <FieldTemplate
      template="check"
      label={question}
      controlName={name}
      required={required}
      status={validationError && 'error'}
      hideLabel
      feedback={validationError}
    >
      <fieldset className={styles.Question}>
        <Type as="legend" size="base-large" className={styles.QuestionText}>
          {question}
        </Type>
        <CheckControlGroup a11yLabel={question}>
          {responses &&
            responses.map((response, i) => (
              <CheckControl
                type="radio"
                name={name}
                value={response.response}
                onChange={handleOnChange}
                key={`Option-${i}-${response.response}`}
              >
                <Type size="base-large" className={styles.Option}>
                  {response.response}
                </Type>
              </CheckControl>
            ))}
        </CheckControlGroup>
      </fieldset>
    </FieldTemplate>
  )
}

Question.propTypes = {
  validationError: string,
  name: string,
  required: bool,
  question: string,
  handleOnChange: func.isRequired,
  responses: arrayOf(shape({ response: string }))
}

export default Questions
