import React from 'react'
import ContactDetails from '../../Common/ContactDetails'
import { string, arrayOf, shape } from 'prop-types'

const ContactsWidget = ({ title, contacts }) => {
  return <ContactDetails title={title} contacts={contacts} />
}

ContactsWidget.propTypes = {
  title: string,
  contacts: arrayOf(
    shape({
      name: string.isRequired,
      telephone: string,
      email: string,
      job: string
    })
  )
}

export default ContactsWidget
