import React from 'react'
import { arrayOf, shape, string, bool, func, object, array } from 'prop-types'
import Results from '@/component/Common/Poll/component/Results'

import Questions from './component/Questions'

import styles from './Poll.module.scss'

const Poll = ({
  form,
  handleOnSubmit,
  handleOnChange,
  validationError,
  serverError,
  successMessage,
  waiting,
  results,
  formStored
}) => {
  const { questions, description, resultsTitle, title } = form || {}
  return (
    <div className={styles.Poll}>
      {!formStored && !successMessage && (
        <Questions
          questions={questions}
          description={description}
          handleOnChange={handleOnChange}
          title={title}
          handleOnSubmit={handleOnSubmit}
          validationError={validationError}
          serverError={serverError}
          waiting={waiting}
        />
      )}
      {(formStored || successMessage) && results && (
        <Results
          questions={results}
          description={description}
          resultsTitle={resultsTitle}
        />
      )}
    </div>
  )
}

Poll.propTypes = {
  formStored: bool,
  handleOnChange: func.isRequired,
  handleOnSubmit: func.isRequired,
  waiting: bool,
  results: array,
  successMessage: string,
  validationError: object,
  serverError: object,
  form: shape({
    title: string,
    description: string,
    questions: arrayOf(
      shape({
        question: string,
        required: bool,
        inputType: string,
        responses: arrayOf(
          shape({
            response: string
          })
        )
      })
    )
  }).isRequired
}

export default Poll
