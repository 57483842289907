import React from 'react'
import Downloads from '@/component/Common/Downloads/'

const Downloadwidget = (props) => {
  const { downloads, title, description, orientation, listStyle } = props || {}
  return (
    <>
      <Downloads
        title={title}
        description={description}
        downloads={downloads}
        orientation={orientation}
        listStyle={listStyle}
      />
    </>
  )
}

export default Downloadwidget
